import React from "react";


class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/woordenlijst/">Woordenlijst</a>
                    </li>
                    <li className="last">
                      <a href="/veelgestelde-vragen-diarree/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li
                      id="uilist-hor nav-top nav-lang_nl-BE"
                      className="active"
                    >
                      <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">
                        NL
                      </a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr">
                      <a href="/fr/comment-agit-imodium/la-substance-active-loperamide/">
                        FR
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="816036ff206a486718d0bb62a3395171886ef88d"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/zoeken"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <h1 className="logo" style={{
                "margin-left": "3px"
              }}><a href="/">Imodium®</a>
                </h1>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="parent-active first" id="nav-sub-4">
                        <a href="/hoe-werkt-imodium-instant/">
                          Hoe werkt
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/imodium-behandelen-diarree/">
                          IMODIUM® <br />
                          Geneesmiddelen
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/feiten-over-diarree/">
                          Diarree - oorzaken
                          <br />
                          en behandeling
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/diarree-op-reis/">
                          Diarree
                          <br />
                          op reis
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/diarree-bij-kinderen/">
                          Diarree
                          <br />
                          bij kinderen
                        </a>
                      </li>                      
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/hoe-werkt-imodium-instant/"> Hoe werkt IMODIUM®? </a>
              </li>
              <li className="active last">
                <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">
                  De werkzame stof: loperamide
                </a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
				<a href="/imodium-behandelen-diarree/">
                <h4>Welke IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/Range_packshot_NL_small.JPG"
                    width="170"
                    height="102"
                    alt="Welke IMODIUM®?"
                  />
                </div>
                <div className="parag" style={{"letter-spacing": "0.5px"}}>
                <p>
                  <p>Zoek uit <strong>welke IMODIUM®</strong> het meest geschikt is voor jou.</p>
                </p>
                </div>
                </a>
              </div>
            </div>
            <div className="widget">
              <div className="inner">
                <h4>Hoe werkt IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/widget.jpeg"
                    width="170"
                    height="102"
                    alt="Hoe werkt IMODIUM®?"
                  />
                </div>
                <p>
                  Deze video laat zien hoe Imodium<sup>®</sup> helpt om je
                  darmen terug in hun natuurlijke balans te brengen.
                </p>
                <a href="/hoe-werkt-imodium-instant/">Meer informatie</a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-11">
            <h1>De werkzame stof van IMODIUM®: loperamide</h1>
            <div className="intro row">
              <div className="three columns">
                <h2>Werkzame stof</h2>
              </div>
              <div className="nine columns">
                <p>
                  <strong>Loperamide</strong> is in België sinds 1974
                  verkrijgbaar onder de naam <strong>IMODIUM®</strong> en wordt
                  gebruikt voor de behandeling van de{" "}
                  <strong>symptomen van diarree</strong>.
                </p>
              </div>
            </div>
            <h4>Wat gebeurt er bij diarree in de darm?</h4>
            <p>
              De meeste mensen kennen de typische symptomen van diarree: te
              frequente en te vloeibare ontlasting. Wat gebeurt er bij{" "}
              <strong>diarree</strong> in je darm?
            </p>
            <p>
              Bij <strong>diarree</strong> hebben we altijd te maken met drie
              veranderingen in de darmfunctie:
            </p>
            <ul>
              <li>
                <strong>Een versterkte darmbeweging</strong>:<br />
                je darm werkt sneller dan normaal, waardoor de darminhoud
                sneller naar de uitgang geleid wordt.
                <br />
                &nbsp;
              </li>
              <li>
                <strong>Verminderde vochtopname uit de darm</strong>:<br />
                bij een normale vertering worden in de darm vocht, elektrolyten
                en voedingsstoffen uit de voeding opgenomen. Door de versterkte
                darmbeweging heeft het lichaam daar echter minder tijd voor.
                Daarom blijft de ontlasting vloeibaar. Aangezien het lichaam nu
                minder vocht en voedingsstoffen krijgt, bestaat het gevaar van
                dehydratatie (uitdroging).
                <br />
                &nbsp;
              </li>
              <li>
                <strong>Versterkte afscheiding of secretie</strong>:<br />
                tegelijk geeft het lichaam meer vocht en elektrolyten in je darm
                af. De ontlasting wordt daardoor steeds vloeibaarder en de
                ontlastingsdrang sterker.
              </li>
            </ul>
            <p>&nbsp;</p>
            <h3>Loperamide werkt deze functiestoornissen in je darm tegen</h3>
            <p>
              De werkzame stof loperamide werkt de drie hierboven genoemde
              functiestoornissen tegen, zodat de ontlasting weer vaster en
              uitdroging voorkomen wordt.
              <br />
              Loperamide vermindert de overmatige darmactiviteit. Daardoor blijft de voeding langer in je darm en kan
              je lichaam er meer vocht en voedingsstoffen uit opnemen. Daarnaast
              wordt de versterkte afscheiding van vocht en elektrolyten in je
              darm weer genormaliseerd. 
            </p>
            <p>&nbsp;</p>
            <h3>Eigenschappen van loperamide</h3>
            <ul>
              <li>
                <strong>Loperamide</strong> werkt <strong>uitdroging</strong>{" "}
                bij diarree tegen doordat het de opname van vocht en
                elektrolyten verbetert.
              </li>
              <li>
                <strong>De duur van je diarree</strong> kan met loperamide
                aanzienlijk <strong>verkort</strong> worden.&nbsp;
              </li>
              <li>
                <strong>Loperamide</strong> helpt je lichaam de stoelgang snel
                opnieuw in te dikken. Dit
                zal ervoor zorgen dat ziekteverwekkende
                kiemen opnieuw minder vat op je darm krijgen.
              </li>
              <li>
                <strong>Loperamide</strong> spant de spier van je anus tijdelijk
                op. Hierdoor{" "}
                <strong>
                  voorkomt loperamide dat je stoelgang verliest{" "}
                </strong>
                en worden gênante situaties je bespaard.
              </li>
            </ul>
            <h3>&nbsp;</h3>
            <h3>Combinatie van Loperamide en Simeticon</h3>
            <p>
              Naast loperamide (2mg) bevat&nbsp;IMODIUM® Duo ook de werkzame
              stof <strong>simeticon</strong> (125mg), om klachten van
              gasvorming in de buikholte te verlichten.{" "}
              <strong>Simeticon</strong> zorgt ervoor dat de gasbellen, die
              krampen en een opgezet gevoel veroorzaken in de dunne darm,
              oplossen. IMODIUM® Duo behandelt doeltreffend acute diarree die
              gepaard gaat met ongemakken zoals winderigheid, krampen en een
              opgeblazen gevoel.
            </p>
          </div>
        </div>
        <footer class="footer"
style={{"height": "501.006px"}}>
            <div class="row">
                <div class="three columns" style={{
                
              }}>
                    <ul>
                        <li class="first last" >
                        <a href="/hoe-werkt-imodium-instant/">Hoe werkt IMODIUM®?</a>
              
                        <ul>
                        <li class="first last">
                        <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">De werkzame stof: loperamide
                        </a>
                        </li>
                        </ul>
                        </li>
                    </ul>

                    <ul>
                        <li class="first last" >
                        <a href="/imodium-behandelen-diarree/">IMODIUM® Geneesmiddelen</a>
              
                          <ul>
                          <li class="first">
                          <a href="/imodium-behandelen-diarree/imodium-instant/">IMODIUM® Instant
                          </a></li>
                          <li>
                            <a href="/imodium-behandelen-diarree/imodium-duo/">IMODIUM® Duo
                          </a></li>
                          <li class="last">
                            <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">IMODIUM® Capsules
                          </a></li>
                          
                          </ul>
                          </li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>
                        <li class="first last" >
                        <a href="/feiten-over-diarree/">Feiten over diarree</a>
              
                        <ul>
                        <li class="first"><a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">Tips voor behandeling</a></li>
                        <li><a href="/feiten-over-diarree/oorzaken-van-diarree/">Oorzaken van diarree</a></li>
                        <li><a href="/feiten-over-diarree/stress-en-diarree/">Stress en diarree</a></li>
                        <li><a href="/feiten-over-diarree/buikgriep/">Virale of bacteriële infectie</a></li>
                        <li><a href="/feiten-over-diarree/norovirus/">Norovirus</a></li>
                        <li><a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">Diarree tijdens de menstruatie</a></li>
                        <li><a href="/feiten-over-diarree/chronische-diarree/">Chronische Diarree</a></li>
                        <li><a href="/feiten-over-diarree/eenartsraadplegen/">Een	arts	raadplegen?</a></li>
                        <li class="last"><a href="/feiten-over-diarree/gezonde-eetgewoonten/">Gezonde eetgewoonten</a></li>
                        </ul>
                        </li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>                        
                    </ul>
                    <ul>
                        <li class="first last" ><a href="/diarree-op-reis/">Diarree op reis</a>
                <ul>
                          <li class="first"><a href="/diarree-op-reis/reizigersdiaree-vermijden/">Reizigersdiarree vermijden</a></li>
                          <li><a href="/diarree-op-reis/behandeling-van-reizigersdiarree/">Reizigersdiarree behandelen</a></li>
                          <li><a href="/diarree-op-reis/reisapotheek/">Reisapotheek</a></li>
                          </ul>
                          </li>

                    </ul>
                </div>
                <div className="three columns" >
                    
                    <ul>
                        <li class="first"><a href="/diarree-bij-kinderen/">Diarree bij kinderen</a></li>                        
                        <li><a href="/woordenlijst/">Woordenlijst diarree</a></li>
                        <li class="last"><a href="/veelgestelde-vragen-diarree/">FAQ</a></li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="twelve columns">
                    <nav class="nav">
                        <ol class="uilist-hor">
                            <li class="first"><a href="/sitemap/">Sitemap</a></li>
                            <li><a href="/wettelijke-verklaring/">Wettelijke verklaring</a></li>
                            <li><a href="/privacybeleid/">Privacybeleid</a></li>
                            <li><a href="/cookiebeleid/">Cookiebeleid</a></li>
                            <li class="last"><a href="/contact/">Contact</a></li>
                            <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings" rel="nofollow">Cookie-instellingen</a>


                        </ol>
                    </nav>
                </div>
            </div>
            <div class="disclaimer">
                <div class="row">
                    <div class="twelve columns">
                        <p>© Johnson &amp; Johnson Consumer N.V. 2017, BTW BE 0401.955.033 - RPR Antwerpen, 2017. Deze site wordt onderhouden door Johnson &amp; Johnson Consumer N.V. die als enige verantwoordelijk is voor de inhoud. Deze site is bestemd voor een publiek uit België en G.H.Luxemburg. Last updated: Thursday, April 22, 2021 </p>
                        <p>IMODIUM<sup>®</sup> Instant / Capsules (loperamide) is een geneesmiddel. Niet toedienen aan kinderen jonger dan 6 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.
                        <br />
                        IMODIUM<sup>®</sup> Duo (loperamidehydrochloride + simeticon ) is een geneesmiddel, niet toedienen aan kinderen jonger dan 12 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.
                        <br />
                        Lees aandachtig de bijsluiter. Vraag advies aan uw arts of apotheker. Vergunninghouder: Johnson &amp; Johnson Consumer N.V. Michel de Braeystraat 52, 2000 Antwerpen, België. Dossiernr: BE-IM-2200031.
                        </p>
                   </div>
                </div>
            </div>
        </footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-nl.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
